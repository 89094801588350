@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.image {
  border: 0;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.image img {
  display: block;
  border-radius: 4px;
}

.image.left,
.image.right {
  width: 40%;
  max-width: 10rem;
}

.image.left img,
.image.right img {
  width: 100%;
}

.image.left {
  float: left;
  margin: 0 1.5rem 1rem 0;
  top: 0.25rem;
}

.image.right {
  float: right;
  margin: 0 0 1rem 1.5rem;
  top: 0.25rem;
}

.image.fit {
  display: block;
  margin: 0 0 2rem 0;
  width: 100%;
}

.image.fit img {
  width: 100%;
}

.image.main {
  display: block;
  margin: 0 0 3rem 0;
  width: 100%;
}

.image.main img {
  width: 100%;
}

.image {
  -moz-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  border-radius: 0;
  width: 312px;
  height: 554px;
  margin-top: 2.5rem;
  margin-bottom: 5rem;
  border-color: rgba(164, 164, 164, 0.641);
}

.image img {
  -moz-object-fit: cover;
  -webkit-object-fit: cover;
  -ms-object-fit: cover;
  object-fit: cover;
  -moz-object-position: center;
  -webkit-object-position: center;
  -ms-object-position: center;
  object-position: center;
  display: block;
  width: 312px;
  height: 100%;
  border-radius: 0;
}

.image:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='64px' height='32px' viewBox='0 0 64 32' zoomAndPan='disable'%3E%3Cstyle%3Erect %7Bfill: transparent%3B stroke: rgba(0, 0, 0, 0.2)%3B stroke-width: 1px%3B %7D%3C/style%3E%3Crect rx='4' ry='4' x='11' y='12' width='42' height='8' vector-effect='non-scaling-stroke' /%3E%3C/svg%3E");
  content: "";
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px;
  border-color: rgba(164, 164, 164, 0.641);
  border-bottom: 0;
  height: 60px;
  background-size: 64px 32px;
  margin-top: -60px;
  border-radius: 1rem 1rem 0 0;
}

.image:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='64px' height='32px' viewBox='0 0 64 32' zoomAndPan='disable'%3E%3Cstyle%3Ecircle %7Bfill: transparent%3B stroke: rgba(0, 0, 0, 0.2)%3B stroke-width: 1px%3B %7D%3C/style%3E%3Ccircle cx='32' cy='16' r='14' vector-effect='non-scaling-stroke' /%3E%3C/svg%3E");
  content: "";
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px;
  border-top: 0;
  height: 60px;
  background-size: 64px 32px;
  margin-bottom: -3rem;
  border-radius: 0 0 1rem 1rem;
  border-color: rgba(164, 164, 164, 0.641);
}

@media screen and (max-width: 980px) {
  .image {
    width: 11.375rem;
    height: 20.22222rem;
    margin-top: 2.1875rem;
    margin-bottom: 4.625rem;
  }

  .image:before {
    height: 2.1875rem;
    background-size: 56px 28px;
    margin-top: -2.1875rem;
    border-radius: 0.875rem 0.875rem 0 0;
  }

  .image:after {
    height: 2.625rem;
    background-size: 56px 28px;
    margin-bottom: -2.625rem;
    border-radius: 0 0 0.875rem 0.875rem;
  }
}

@media screen and (max-width: 736px) {
  .image {
    width: 8.125rem;
    height: 14.44444rem;
    margin-top: 1.5625rem;
    margin-bottom: 3.875rem;
  }

  .image:before {
    height: 1.5625rem;
    background-size: 40px 20px;
    margin-top: -1.5625rem;
    border-radius: 0.625rem 0.625rem 0 0;
  }

  .image:after {
    height: 1.875rem;
    background-size: 40px 20px;
    margin-bottom: -1.875rem;
    border-radius: 0 0 0.625rem 0.625rem;
  }
}
